import { createReducer, on } from '@ngrx/store';
import * as FormActions from '../actions/insure-quote.action';


export const initialQuoteState: any = {
  form: {}
};


export const formReducer = createReducer(
  initialQuoteState,
  on(FormActions.addItem, (state, { insureForm }) => ({
    ...state,
    formData: insureForm,
  })),
  // Add more reducers as per your requirements
);
