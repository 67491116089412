import { createReducer, on } from '@ngrx/store';
import { QuoteState } from '../states/quote.state';
import * as QuoteActions from '../actions/motor-quote.action';

export const initialQuoteState: any = {}

export const quoteMotorReducer = createReducer(
  initialQuoteState,
  on(QuoteActions.loadMotorQuoteSuccess, (state, { motorQuote }) => ({
    ...state,
    motorQuote,
  })),
  // Add more reducers as per your requirements
);
